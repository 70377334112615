var _DefaultConsent;

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

export var CookieName = {
  INTERACTED_WITH_COOKIES: 'interactedWithCookies',
  COOKIES_CONSENT: 'cookiesConsent'
};
export var InteractedWithCookies = {
  INTERACTED: 'interacted',
  DID_NOT_INTERACT: 'didNotInteract'
};
export var CookieConsentState = {
  DENIED: 'denied',
  GRANTED: 'granted'
};
export var CookieConsentType = {
  ANALYTICS_STORAGE: 'analytics_storage',
  PERSONALIZATION_STORAGE: 'personalization_storage'
};
export var DefaultConsent = (_DefaultConsent = {}, _defineProperty(_DefaultConsent, CookieConsentType.ANALYTICS_STORAGE, CookieConsentState.DENIED), _defineProperty(_DefaultConsent, CookieConsentType.PERSONALIZATION_STORAGE, CookieConsentState.DENIED), _DefaultConsent);