export var getAYearFromNow = function getAYearFromNow() {
  return new Date(new Date().setFullYear(new Date().getFullYear() + 1));
};
export var getADayFromNow = function getADayFromNow() {
  return new Date(Date.now() + 3600 * 1000 * 24);
};
export function gtag() {
  window.dataLayer.push(arguments);
}
export var updateGTMConsents = function updateGTMConsents(consents) {
  window.dataLayer = window.dataLayer || [];
  gtag('consent', 'update', consents);
};
export function sendConsentPageView() {
  gtag('event', 'page_view_cookie_consent', {
    pageUrl: window.location.href,
    route: window.location.pathname,
    pageTitle: document.title
  });
}
export var handleEsc = function handleEsc(event, callback) {
  if (event.keyCode === 27) {
    callback();
  }
};