function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

import Cookies from 'universal-cookie';
import { CookieConsentState, CookieConsentType, CookieName } from '../consts';
import { getAYearFromNow } from './utils';
var inAYear = getAYearFromNow();
export var prepareCookies = function prepareCookies() {
  var cookies = new Cookies();

  if (cookies.get(CookieName.COOKIES_CONSENT)) {
    return cookies;
  } else {
    var _consents;

    var consents = (_consents = {}, _defineProperty(_consents, CookieConsentType.ANALYTICS_STORAGE, CookieConsentState.DENIED), _defineProperty(_consents, CookieConsentType.PERSONALIZATION_STORAGE, CookieConsentState.DENIED), _consents);
    cookies.set(CookieName.COOKIES_CONSENT, consents, {
      path: '/',
      expires: inAYear
    });
  }

  return cookies;
};