import { domReady } from './js/domReady';
import { injectCookiesBar } from './js/cookiesBar';
import { prepareCookies } from './js/prepareCookies';
import { CookieName, InteractedWithCookies } from './consts';
import { injectCookiesSettings } from './js/cookiesSetting';
domReady(function () {
  var cookies = prepareCookies();
  var shoudlInjectCookiesBar = cookies.get(CookieName.INTERACTED_WITH_COOKIES) === InteractedWithCookies.INTERACTED;

  if (cookies && !shoudlInjectCookiesBar) {
    injectCookiesBar({
      cookies: cookies
    });
  }

  window.injectCookiesSettings = function () {
    return injectCookiesSettings({
      cookies: cookies
    });
  };
});