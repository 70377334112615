import { CookieConsentState, CookieName } from '../consts';
import { closeAndSaveCookiesBar } from './cookiesBar';
import { toggleModal } from './cookiesModal';
import { getAYearFromNow, sendConsentPageView, updateGTMConsents } from './utils';
var inAYear = getAYearFromNow();
export var setConsentCookies = function setConsentCookies(_ref) {
  var cookies = _ref.cookies,
      consents = _ref.consents;
  cookies.set(CookieName.COOKIES_CONSENT, consents, {
    path: '/',
    expires: inAYear
  });
  closeAndSaveCookiesBar({
    cookies: cookies,
    expires: inAYear
  });
  toggleModal({
    cookies: cookies,
    forceUnmount: true
  });
  updateGTMConsents({
    analytics_storage: 'granted',
    personalization_storage: 'granted'
  });
};
export var handleAllowAll = function handleAllowAll(_ref2) {
  var cookies = _ref2.cookies;
  var consents = cookies.get(CookieName.COOKIES_CONSENT);
  Object.keys(consents).forEach(function (key) {
    consents[key] = CookieConsentState.GRANTED;
  });
  setConsentCookies({
    cookies: cookies,
    consents: consents
  });
  sendConsentPageView();
};